import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('stats-section__slider')[0]) {
  const statsSlidersArr = document.querySelectorAll('.stats-section__slider');
  statsSlidersArr.forEach((sliderEl, sliderIdx) => {
    const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    let pauseOnMouseEnter = window.innerWidth > 1024;

    let allowTouchMove = true;

    let autoplay = false;
    let autoplayConfig = {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: pauseOnMouseEnter,
    };

    if (slides.length >= 3) {
      loop = true;
      loopedSlides = slides.length * 4;
    } else if (window.innerWidth <= 641 && slides.length >= 2) {
      loop = true;
      loopedSlides = slides.length * 4;
    }

    if (window.innerWidth >= 641 && slides.length >= 4) {
      autoplay = autoplayConfig;
    } else if (window.innerWidth < 641) {
      autoplay = autoplayConfig;
    } else {
      allowTouchMove = false;
    }

    const statsSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 1,
      spaceBetween: 0,
      threshold: 10,
      loop: loop,
      loopedSlides: loopedSlides,
      autoplay: autoplay,
      allowTouchMove: allowTouchMove,
    });

    if (window.innerWidth >= 641 && slides.length >= 4) {
      swiperObserver(statsSwiper);
    } else if (window.innerWidth < 641) {
      swiperObserver(statsSwiper);
    } else {
      statsSwiper.slideTo(1);
    }
  });
}
