import Swiper, { Autoplay, Navigation, Scrollbar, Controller } from 'swiper';
import { EffectFade } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.querySelector('.hero__slider')) {
  const swiper = new Swiper('.hero__slider', {
    modules: [Navigation, Autoplay],
    slidesPerView: 1,
    spaceBetween: 0,
    speed: 500,
    /*autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
		},*/
    allowTouchMove: false,
    noSwiping: true,
    navigation: {
      prevEl: '.hero .slider-btn.prev',
      nextEl: '.hero .slider-btn.next',
    },
  });
  /*swiperObserver(swiper);*/
}

