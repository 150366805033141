import Swiper, { Autoplay, Navigation, Scrollbar } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let workSwiperWr = document.querySelectorAll('.work-swiper-wr');
workSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.slider-btn.next');
    let prevEl = el.querySelector('.slider-btn.prev');
    let slides = el.querySelectorAll('.swiper-slide');
    let slidesCount = slides.length;
     let wrapper = swiperEl.querySelector('.swiper-wrapper');
     let scrollbar = el.querySelector('.swiper-scrollbar');
     let paginationEL = el.querySelector('.swiper-pagination');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation, Scrollbar],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      spaceBetween: 15,
      slidesPerView: 'auto',
      speed: 800,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      scrollbar:{
        el: scrollbar,
        draggable: true,
      },
      breakpoints: {
        1024: {
          spaceBetween: 20,
          scrollbar:{
            el: scrollbar,
            draggable: true,
            dragSize:183,
          },
        },
      },
      on:{
        init: function(){
          let headers = swiperEl.querySelectorAll('.work-card__header')
          setTimeout(function(){
            window.setSameHeight(headers)
          },100)

        },
        resize: function(){
          let headers = swiperEl.querySelectorAll('.work-card__header')
          window.setSameHeight(headers);
        }
      },

      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
    });
    swiperObserver(swiper);
  }
});
