window.autosizeTextarea = function autosizeTextarea() {
  if (document.querySelector('textarea[data-autosize]')) {
    const autosizeTextareaElements = document.querySelectorAll('textarea[data-autosize]');
    autosizeTextareaElements.forEach((textarea) => {
      const startHeight = textarea.offsetHeight;
      textarea.addEventListener('input', autosize);
      function autosize() {
        const el = this;
        const newHeight = el.scrollHeight;
        if (newHeight > startHeight) {
          el.style.minHeight = `${el.scrollHeight}px`;
        } else {
          el.style.minHeight = startHeight;
        }
      }
    });
  }
};
autosizeTextarea();

const defaultCustomSelect = document.getElementsByClassName('default-custom-select')[0];
if (defaultCustomSelect) {
  const defaultCustomSelectArr = document.querySelectorAll('.default-custom-select');

  defaultCustomSelectArr.forEach((customSelect, i) => {
    const zIndexValue = defaultCustomSelectArr.length - i;
    customSelect.style.zIndex = zIndexValue + 10;

    const customSelectPlaceholder = customSelect.querySelector('.default-custom-select__placeholder');
    const customSelectList = customSelect.querySelector('.default-custom-select__list');
    const customSelectPlaceholderTextContent = customSelect.querySelector('.default-custom-select__placeholder').textContent;

    customSelect.addEventListener('click', (e) => {
      if (!customSelect.classList.contains('active')) {
        customSelect.classList.add('active');
        console.log(customSelect);
      } else {
        if (!e.target.classList.contains('default-custom-select__list')) {
          customSelect.classList.remove('active');
        }
      }
    });

    document.addEventListener('click', (event) => {
      if (!customSelectList.contains(event.target) && !customSelect.contains(event.target)) {
        customSelect.classList.remove('active');
      }
    });

    const checkboxesSelect = customSelect.querySelectorAll('input[type="checkbox"]');

    function placeholderCheck() {
      const checkedCount = customSelect.querySelectorAll('input[type="checkbox"]:checked').length;
      console.log(customSelect.querySelectorAll('input[type="checkbox"]:checked'));

      if (checkedCount === 1) {
        const checkedItem = customSelect.querySelector('input[type="checkbox"]:checked');
        customSelectPlaceholder.textContent = checkedItem.nextElementSibling.nextElementSibling.textContent;
      } else {
        customSelectPlaceholder.textContent = checkedCount > 0 ? `${checkedCount} items selected` : customSelectPlaceholderTextContent;
      }
    }

    checkboxesSelect.forEach((checkbox) => {
      checkbox.addEventListener('change', () => {
        placeholderCheck();
      });
    });

    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    for (let j = 0; j < checkboxes.length; j++) {
      checkboxes[j].addEventListener('change', () => {
        placeholderCheck();
      });
    }
  });
}

const singleRangeSlider = () => {
  const singleRange = document.getElementsByClassName('single-range-default')[0];
  if (singleRange) {
    const slider = singleRange.querySelector('#single-range-default');
    const max = +slider.dataset.max;
    const min = +slider.dataset.min;
    const unit = slider.dataset?.unit || '£';
    const step = +slider.dataset.step;
    const inputsHidden = singleRange.querySelector('.single-range-hidden-input');
    const inputInfo = singleRange.querySelector('.single-range-info');
    const startValue = +inputsHidden.value;

    noUiSlider.create(slider, {
      start: [startValue],
      connect: 'lower',
      margin: 10,
      step: step,
      range: {
        min: min,
        max: max,
      },
    });

    slider.noUiSlider.on('update', function (values, handle) {
      inputInfo.textContent = `${Math.round(values[handle])} ${unit}`;

      inputsHidden.value = Math.round(values[handle]);
    });
  }
};
singleRangeSlider();
