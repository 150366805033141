import Swiper, { Autoplay, Navigation, Controller } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let journeySwiperWr = document.querySelectorAll('.journey-swiper-wr');
journeySwiperWr.forEach((el) => {
  if (el) {
    let logoSwiperEl = el.querySelector('.swiper-logo');
    let textSwiperEl = el.querySelector('.swiper-text');
    let nextEl = el.querySelector('.slider-btn.next');
    let prevEl = el.querySelector('.slider-btn.prev');
    let slidesCount = logoSwiperEl.querySelectorAll('.swiper-slide').length;

    let logoSwiper = new Swiper(logoSwiperEl, {
      modules: [Autoplay, Navigation, Controller],
      spaceBetween: 0,
      speed: 800,
      threshold: 10,
      slidesPerView: 1,
      breakpoints: {
      },
    });

    let textSwiper = new Swiper(textSwiperEl, {
      modules: [Autoplay, Navigation, Controller],
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      spaceBetween: 20,
      slidesPerView: 1,
      speed: 800,
      threshold: 10,
      autoHeight: true,
      autoplay: {
                delay: 5000,
                disableOnInteraction: false,
                pauseOnMouseEnter: false,
            },
      breakpoints: {
        851: {
          autoHeight: false,
        },
      },

      on: {
        afterInit: function (swiper) {
          let slides = textSwiperEl.querySelectorAll('.swiper-slide');
          if (slides) {
            slides.forEach((slide, idx) => {
              let scrollContainer = slide.querySelector('.journey-text-card__description');
               setTimeout(function(){
                 setBoxMbCounter(scrollContainer, 'p');
               },100)
            });
            swiper.update();
          }
        },
      },
    });

    swiperObserver(textSwiper);

    logoSwiper.controller.control = textSwiper;
    textSwiper.controller.control = logoSwiper;
  }
});

