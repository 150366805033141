import {
	computePosition,
	shift,
	limitShift,
	offset,
	flip,
} from '@floating-ui/dom';

import MetaMap from '../libs/metaMap.lib.js';
import { is_touch_enabled } from '../utils/navigator.utils.js';

const mapSection = document.querySelector('.map-section');

if (mapSection) {
	const mapWrap = mapSection.querySelector('#map');
	const popover = mapSection.querySelector('.map-popover');
	const imgPopover = popover.querySelector('.map-popover__img');

	const isTab = matchMedia('(max-width: 768px)').matches;

	new MetaMap('#map', {
		mapPath: './assets/static/map.geo.json',
		width: 1300,
		height: isTab ? 1470 : 920,
		isZoomable: is_touch_enabled(),
		countryStrokeWidth: 0,
		countryStrokeColor: '#ffffff',
		markerStyle: {
			type: 'pin',
			color: '#E0195B',
			width: '16px',
			height: '16px',
			radius: '8',
			strokeWidth: 3,
			shadow: {
				color: '#E0195B',
				blur: 10,
				radius: 10,
			},
		},
		zoomDefault: isTab ? 2.5 : null,
		translateDefault: isTab ? [13.628032, 21.666104] : null,
		markers: [
			{
				lat: 51.509865,
				long: -0.118092,
				info: {
					logo: 'img/JCB.svg',
				},
			},
			{
				lat: 36.737232,
				long: 3.086472,
				info: {
					logo: 'img/JCB.svg',
				},
			},
			{
				lat: 22.785,
				long: 5.5228,
				info: {
					logo: 'img/JCB.svg',
				},
			},
			{
				lat: 52.237049,
				long: 21.017532,
				info: {
					logo: 'img/JCB.svg',
				},
			},
			{
				lat: 48.002777,
				long: 37.805279,
				info: {
					logo: 'img/JCB.svg',
				},
			},
			{
				lat: 21.348711,
				long: 45.589916,
				info: {
					logo: 'img/JCB.svg',
				},
			},
			{
				lat: 48.189574,
				long: 16.362777,
				info: {
					logo: 'img/JCB.svg',
				},
			},
			{
				lat: 41.009321,
				long: 28.964229,
				info: {
					logo: 'img/JCB.svg',
				},
			},
			{
				lat: 45.410166,
				long: -75.718386,
				info: {
					logo: 'img/JCB.svg',
				},
			},
			{
				lat: 32.771396,
				long: -79.971926,
				info: {
					logo: 'img/JCB.svg',
				},
			},
			{
				lat: 47.683419,
				long: -100.989439,
				info: {
					logo: 'img/JCB.svg',
				},
			},
			{
				lat: 46.220114,
				long: -117.09612,
				info: {
					logo: 'img/JCB.svg',
				},
			},
			{
				lat: 32.609396,
				long: -113.949713,
				info: {
					logo: 'img/JCB.svg',
				},
			},
			{
				lat: -0.888321,
				long: -47.831547,

				info: {
					logo: 'img/JCB.svg',
				},
			},
			{
				lat: -10.06362,
				long: -70.411506,

				info: {
					logo: 'img/JCB.svg',
				},
			},
			{
				lat: 28.467168,
				long: 110.291775,

				info: {
					logo: 'img/JCB.svg',
				},
			},
			{
				lat: 12.88002,
				long: 107.709077,

				info: {
					logo: 'img/JCB.svg',
				},
			},
		],
		pattern: (defs) => {
			const pattern = defs
				.append('pattern')
				.attr('id', 'pattern')
				.attr('patternUnits', 'userSpaceOnUse')
				.attr('width', 9)
				.attr('height', 9);

			return pattern
				.append('circle')
				.attr('cx', 4.5)
				.attr('cy', 4.5)
				.attr('r', 3)
				.style('fill', '#24365B');
		},
		patternGradient: (defs) => {
			const patternGradient = defs
				.append('linearGradient')
				.attr('id', 'patternGradient')
				.attr('gradientUnits', 'userSpaceOnUse');
			patternGradient.append('stop').attr('stop-color', '#EDAAC0');
			patternGradient
				.append('stop')
				.attr('stop-color', '#B1B3C0')
				.attr('offset', '1');

			return patternGradient;
		},
		on: {
			markerClick: ({ data, target }) => {
				const activeMarkers =
					mapWrap.querySelectorAll('.marker.active');
				activeMarkers.forEach((marker) =>
					marker.classList.remove('active')
				);

				target.classList.add('active');

				popover.classList.add('active');

				imgPopover.src = data.info.logo;

				updatePosition(target, popover);
			},
			zoom: () => {
				const activeMarkers =
					mapWrap.querySelectorAll('.marker.active');
				activeMarkers.forEach((marker) =>
					marker.classList.remove('active')
				);

				popover.classList.remove('active');
			},
		},
	});

	window.addEventListener('click', ({ target }) => {
		if (!target.classList.contains('marker')) {
			const activeMarkers = mapWrap.querySelectorAll('.marker.active');
			activeMarkers.forEach((marker) =>
				marker.classList.remove('active')
			);

			popover.classList.remove('active');
		}
	});
}

function updatePosition(pin, popover) {
	computePosition(pin, popover, {
		placement: 'top',
		strategy: 'fixed',
		middleware: [
			flip(),
			shift({
				crossAxis: true,
				limiter: limitShift(),
			}),
			offset(10),
		],
	}).then(({ x, y }) => {
		Object.assign(popover.style, {
			left: `${x}px`,
			top: `${y}px`,
		});
	});
}
