function setParallax(element, effectSpeed) {
  element.style.opacity = 1;

  function handleScroll() {
    const defaultSpeed = 0.1;
    const originalSpeed =
      parseFloat(element.getAttribute('data-parallax-view-speed')) ||
      defaultSpeed;
    const speed = originalSpeed / 10;
    const scroll = window.scrollY + window.innerHeight;
    const elPos = element.getBoundingClientRect().top + window.scrollY;
    let newPos = 0;

    /*let start = true;

    if(element.hasAttribute('data-parallax-view-not-mobile') === true && window.innerWidth <=640) {
      start = false;
    }*/

    if (elPos + element.clientHeight >= window.scrollY) {
      if (window.innerHeight >= elPos) {
        newPos = window.scrollY * speed;
        if (element.hasAttribute('data-parallax-view-horizontal') === true) {
          element.style.transform = `translateX(${-newPos}px)`;
        } else {
          element.style.transform = `translateY(${-newPos}px)`;
        }
      } else if (scroll >= elPos) {
        newPos = (elPos - (window.scrollY + window.innerHeight)) * speed;
        if (element.hasAttribute('data-parallax-view-horizontal') === true) {
          element.style.transform = `translateX(${newPos}px)`;
        } else {
          element.style.transform = `translateY(${newPos}px)`;
        }
      }
    }
  }

  window.addEventListener('scroll', handleScroll);
  handleScroll();
}

const parallaxElements = document.querySelectorAll('[data-parallax-view]');
parallaxElements.forEach((element) => {
  setParallax(element);
});
