import Swiper, { Autoplay, Navigation, Scrollbar } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let edJobsSwiperWr = document.querySelectorAll('.ed-jobs-swiper-wr');
edJobsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.slider-btn.next');
    let prevEl = el.querySelector('.slider-btn.prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
     let wrapper = swiperEl.querySelector('.swiper-wrapper');
     let scrollbar = el.querySelector('.swiper-scrollbar');
     let paginationEL = el.querySelector('.swiper-pagination');
     let initialSlide = function(){
       let initialSlide = 0;
       switch (slidesCount){
         case 3: initialSlide = 2;
         case 2: initialSlide = 1;
         case 1: initialSlide = 0;
         default: initialSlide = 3;
       }
       return initialSlide;
     }

    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation, Scrollbar],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      spaceBetween: 15,
      slidesPerView: 1,
      speed: 800,
      threshold: 10,
      initialSlide: initialSlide(),
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      scrollbar:{
        el: scrollbar,
        draggable: true,
      },
      breakpoints: {
        1024: {
          spaceBetween: 20,
        },
      },

      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
    });
    swiperObserver(swiper);
  }
});
