import Swiper, { Autoplay, Navigation, EffectCreative } from 'swiper';
import { swiperOnResize } from '../utils/swiperOnResize.utils.js';

let valuesSwiperWr = document.querySelectorAll('.values-swiper-wr');
valuesSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.slider-btn.next');
    let prevEl = el.querySelector('.slider-btn.prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');


    swiperOnResize('(max-width: 1023px)', swiperEl,
        {
          modules: [Autoplay, Navigation],
          slidesPerView: 1,
          spaceBetween: 20,
          speed: 800,
          autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          },
          breakpoints: {
            550: {
              slidesPerView: 2,
            },
            850: {
              slidesPerView: 3,
            },
          },
          navigation: {
            prevEl: prevEl,
            nextEl: nextEl,
          },
        },
    );
  }
});