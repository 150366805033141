import Swiper, {Navigation, Autoplay, Scrollbar, Controller, EffectFade} from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.querySelector('.sectors__slider')) {
  const swiperMain = new Swiper('.sectors__main-slider', {
    modules: [Navigation, Autoplay, Scrollbar, Controller, EffectFade],
    slidesPerView: 1,
    spaceBetween: 12,
    effect: window.innerWidth < 551 ? 'slide' : 'fade',
    speed: 500,
   /* autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },*/
    rewind: true,
    // allowTouchMove: false,
    // noSwiping: true,
    scrollbar: {
      el: '.sectors .swiper-scrollbar',
      draggable: true,
    },
    navigation: {
      prevEl: '.sectors .slider-btn.prev',
      nextEl: '.sectors .slider-btn.next',
    },
    breakpoints: {
      551: {
        spaceBetween: 0,
      },
    },
  });

  const swiper = new Swiper('.sectors__slider', {
    modules: [Navigation, Autoplay, Scrollbar, Controller],
    slidesPerView: 1,
    spaceBetween: 16,
    rewind: true,
  });

  swiperMain.controller.control = swiper;
  swiper.controller.control = swiperMain;

/*  swiperObserver(swiperMain);*/
}

