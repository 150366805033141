let ectsToggle = document.querySelectorAll('.ects-options-item__header');
if(ectsToggle){
  ectsToggle.forEach(button=>{
    button.addEventListener('click', function(){
      this.classList.toggle('active');
      $(this).toggleClass('active');
      $(this).find('.toggle-btn').toggleClass('active');
      $(this).next().toggleClass('active');
    })
  })
}