import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let benefitsSwiperWr = document.querySelectorAll('.benefits-swiper-wr');
benefitsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.slider-btn.next');
    let prevEl = el.querySelector('.slider-btn.prev');
    let slides = el.querySelectorAll('.swiper-slide');
    let slidesCount = slides.length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let scrollbar = el.querySelector('.swiper-scrollbar');
    let paginationEL = el.querySelector('.swiper-pagination');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      spaceBetween: 8,
      slidesPerView: 'auto',
      speed: 800,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        851: {
          slidesPerView: 3,
        },
        1101: {
          slidesPerView: 4,
        },
      },

      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
    });
    swiperObserver(swiper);
  }
});
