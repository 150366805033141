import Swiper , {EffectFade} from 'swiper';


let contactSwiperWr = document.querySelectorAll('.contact-swiper-wr');
contactSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
     let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [EffectFade],
      effect: 'fade',
  /*    initialSlide: 1,*/
      autoHeight: true,
      fadeEffect:{
        crossFade: true
      },
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      spaceBetween: 24,
      speed: 800,
      threshold: 10,
      allowTouchMove: false,
    });

    let switcherButtons = document.querySelectorAll('.switch-btn');
    let switcherButtonsBg = document.querySelector('.switch-bg');

    if (switcherButtons) {
      switcherButtons.forEach((button, idx) => {
        button.addEventListener('click', function () {
          switcherButtonsBg.style.left = this.offsetLeft + 'px';
          switcherButtonsBg.style.top = this.offsetTop + 'px';
          switcherButtonsBg.style.width = this.clientWidth + 'px';
          switcherButtons.forEach((button, i) =>
              i != idx ? button.classList.remove('active') : ''
          );
          this.classList.add('active');
          swiper.slideTo(idx);
        });
        if(idx===0){
          switcherButtonsBg.style.left = button.offsetLeft + 'px';
          switcherButtonsBg.style.top = button.offsetTop + 'px';
          switcherButtonsBg.style.width = button.clientWidth + 'px';
        }
      });
      window.addEventListener('resize', ()=>{
        let activeBtn = document.querySelector('.switch-btn.active');
        switcherButtonsBg.style.left = activeBtn.offsetLeft + 'px';
        switcherButtonsBg.style.top = activeBtn.offsetTop + 'px';
        switcherButtonsBg.style.width = activeBtn.clientWidth + 'px';
      })
    }
  }
});



