import Swiper, { Autoplay, Navigation, EffectFade, Scrollbar, Pagination } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let clientsTsSwiperWr = document.querySelectorAll('.clients-ts-swiper-wr');
clientsTsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.slider-btn.next');
    let prevEl = el.querySelector('.slider-btn.prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let scrollbar = el.querySelector('.swiper-scrollbar');
    let paginationEL = el.querySelector('.swiper-pagination');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation, EffectFade, Scrollbar, Pagination],
      centeredSlides: true,
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      spaceBetween: 10,
      speed: 800,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      scrollbar: {
        el: scrollbar,
        draggable: true,
      },
      pagination: {
        type: 'fraction',
        el: paginationEL,
      },
      autoHeight: true,
      breakpoints: {

        1024: {
          spaceBetween: 20,
        },
      },

      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
    });
    swiperObserver(swiper);
  }
});
