import Swiper, {
  Autoplay,
  Navigation,
  Controller,
} from 'swiper';
import {swiperObserver} from '../utils/swiper.utils.js';


let jobsWrapper = document.querySelectorAll('.jobs__wrapper')
if (jobsWrapper) {
  jobsWrapper.forEach(el=>{
    let swiperEl = el.querySelector('.swiper');
    let swiperEl2 = el.querySelectorAll('.swiper')[1];
    let nextEl = el.querySelector('.swiper-button-next')
    let prevEl = el.querySelector('.swiper-button-prev')
    let slidesCount = swiperEl.querySelectorAll('.swiper-slide').length;
    let slidesCount2 = swiperEl2?.querySelectorAll('.swiper-slide').length;

    let initialSlide = swiperEl2 && slidesCount > 1 ? 1 : 0;
    let initialSlide2 = swiperEl2 && slidesCount2 > 1 ? 1 : 0;

    const swiperTop = new Swiper(swiperEl , {
      modules: [Navigation, Autoplay, Controller],
      slidesPerView: 'auto',
      spaceBetween: 20,
      speed: 500,
      initialSlide: initialSlide,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: '.jobs .slider-btn.prev',
        nextEl: '.jobs .slider-btn.next',
      },

    });
    swiperObserver(swiperTop);

    if(swiperEl2){
      const swiperBot = new Swiper(swiperEl2 , {
        modules: [Navigation, Autoplay, Controller],
        slidesPerView: 'auto',
        spaceBetween: 20,
        speed: 500,
        initialSlide: initialSlide2,

        /*autoplay: {
          delay: 4000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },*/
        navigation: {
          prevEl: '.jobs .slider-btn.prev',
          nextEl: '.jobs .slider-btn.next',
        },
      });
      swiperTop.controller.control = swiperBot
      swiperBot.controller.control = swiperTop

    }
  })

}



