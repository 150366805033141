let talentToggle = document.querySelectorAll('.talent-item');
function toggleTalentItems(arr){
  if(arr){
    arr.forEach((button, idx)=>{
      button.addEventListener('click', function(){
        let targetIdx = idx;
        arr.forEach((button, idx)=>{
          if(targetIdx !== idx){
            button.classList.remove('active');
          }
        })
        button.classList.toggle('active');
      })
    })
  }
}

toggleTalentItems(talentToggle)
