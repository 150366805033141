const headerDropdownInit = () => {

    if (document.querySelector('.js-dropdown')) {
        const btns = document.querySelectorAll('.js-dropdown');

        btns.forEach(btn => {
            let timer;

            btn.addEventListener('click', (e) => {
                let topLink = btn.querySelector('.header__link');
                let dropdown = btn.querySelector('.header__dropdown')
                let radius = parseFloat(getComputedStyle(dropdown).getPropertyValue('border-top-right-radius'));

                if(topLink.clientWidth >= dropdown.clientWidth - radius){
                    btn.classList.add('parent-radius');
                }else{
                    btn.classList.remove('parent-radius');
                }

                if (e.target.classList.contains('header__link--btn') && e.target.tagName !== 'A' || e.target.classList.contains('header__link-chevrone')) {
                    e.preventDefault();
                    btns.forEach(btnTrigger => {
                        if (btnTrigger !== btn) {
                            btnTrigger.classList.remove('open');
                        }
                    });

                    if (!btn.classList.contains('open')) {
                        btn.classList.add('open');
                    } else {
                        btn.classList.remove('open');
                    }
                }
            });

            // btn.addEventListener('mouseenter', () => {
            //     btns.forEach(btnTrigger => {
            //         if (btnTrigger !== btn) {
            //             btnTrigger.classList.remove('open');
            //         }
            //     });
            //     btn.classList.add('open');

            //     clearTimeout(timer);
            // });

            // btn.addEventListener('mouseleave', () => {
            //     timer = setTimeout(() => {
            //         btn.classList.remove('open');
            //     }, 10);
            // });
        });
    }
}

headerDropdownInit()
