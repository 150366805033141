window.accordionInit = function accordionInit(accordionEl) {
  if (!accordionEl.classList.contains('is-init')) {
    accordionEl.classList.add('is-init');

    const itemsArr = accordionEl.querySelectorAll('.faq-section__item');

    itemsArr.forEach((item, i) => {
      const btn = item.querySelector('.faq-section__item-btn');
      const content = item.querySelector('.faq-section__item-content');

      /*item.addEventListener('click', (event) => {
        if (event.target !== btn) {
          openAccordion(item);
        } else if (event.target === btn && item.classList.contains('active')) {
          item.classList.remove('active');
        } else {
          openAccordion(item);
        }
      });*/

      item.addEventListener('click', (event) => {
        if (item.classList.contains('active')) {
          item.classList.remove('active');
        } else {
          openAccordion(item);
        }
      });

      function openAccordion(item) {
        for (let j = 0; j < itemsArr.length; j++) {
          itemsArr[j].classList.remove('active');
        }

        item.classList.add('active');
        content.style.maxHeight = `${itemCalcMaxHeight(item, content)}px`;
      }

      function itemCalcMaxHeight(item, content) {
        const children = Array.from(content.children);
        const totalHeight = children.reduce((acc, child) => {
          const childHeight = child.offsetHeight;
          return acc + childHeight;
        }, 0);
        return totalHeight + 100;
      }
    });
  }
};

const faqAccordion = document.getElementsByClassName('faq-section__accordion')[0];

if (faqAccordion) {
  const faqAccordionsArr = document.querySelectorAll('main .faq-section__accordion');

  faqAccordionsArr.forEach((accordionEl) => {
    accordionInit(accordionEl);
  });
}
