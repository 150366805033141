import './components/global-functions.component.js';
import './utils/vh.utils.js';
import './utils/accardion.utils.js';
import './utils/nameCut.utils.js';
import './utils/parallax-on-view.utils.js';

import './components/header.component.js';
import './components/headerDropdown.component.js';
import './components/inputs.component.js';
import './components/map.component.js';
import './components/searchesAccordion.component.js';
import './components/ects.component.js';
import './components/talent.component.js';
import './components/faq.component.js';
import './components/jobs-search.component.js';
import './components/meta-select.component.js';
import './components/marquee.component.js';
import './components/paralax.component.js';
import './components/double-range-slider.component.js';

/*Swipers*/
import './components/swiper-hero-home.component.js';
import './components/swiper-stats.component.js';
import './components/swiper-sectors.component.js';
import './components/swiper-vision.component.js';
import './components/swiper-ethos.component.js';
import './components/swiper-values.component.js';
import './components/swiper-journey.component.js';
import './components/swiper-team.component.js';
import './components/swiper-candidate-ts.component.js';
import './components/swiper-clients-ts.component.js';
import './components/swiper-work.component.js';
import './components/swiper-benefits.component.js';
import './components/swiper-ed-jobs.component.js';
import './components/swiper-jobs.component.js';
import './components/swiper-contact.component.js';
import './components/swiper-insights.component.js';
/*end of swipers*/

import './libs/countUp.lib.js';
import './libs/aos.lib.js';

function getOS() {
  const mainWrapper = document.querySelector('#site');
  const platform = window.navigator?.userAgentData?.platform || window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

  if (macosPlatforms.indexOf(platform) !== -1) {
    mainWrapper.classList.add('safari');
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    mainWrapper.classList.add('safari');
  }
}

getOS();

// popup FD
/*import scrollLock from 'scroll-lock';
import SimpleBar from 'simplebar';*/
import { countUpInit } from './libs/countUp.lib.js';

var popupFD = document.querySelector('#popup');
if (popupFD) {
  /*var popupTitle = popupFD.querySelector(".popup__title");*/
  var popupBlock = popupFD.querySelector('#popup-block');

  window.pushPopup = function pushPopup(parentBlock, classHelper = '') {
    console.log(parentBlock);
    if (classHelper) {
      const cls = classHelper.split(' ');
      popupBlock.classList.add(...cls);
    }

    popupBlock.innerHTML = '';
    popupBlock.innerHTML = document.querySelector(parentBlock).innerHTML;

    popupFD.classList.add('active');
    scrollLock.disablePageScroll(document.querySelector('.navbar-nav .menu'));
    scrollLock.addScrollableSelector('.simplebar-content-wrapper');

    const scrollContentElement = document.querySelector('.popup__content');
    console.log(scrollContentElement);

    if (scrollContentElement) {
      const simpleBarInstance = new SimpleBar(scrollContentElement);
      simpleBarInstance.recalculate();
    }

    scrollLock.addScrollableSelector('.simplebar-content-wrapper');

    const faqAccordion = document.getElementsByClassName('faq-section__accordion')[0];

    if (faqAccordion) {
      const faqAccordionsArr = document.querySelectorAll('.popup .faq-section__accordion');

      faqAccordionsArr.forEach((accordionEl) => {
        accordionInit(accordionEl);
      });
    }

    window.initChoices('[data-choices-popup]');

    window.popupClassicSimplebarInit();
  };
}

window.closePopup = function closePopup() {
  document.querySelector('#popup').classList.remove('active');
  scrollLock.clearQueueScrollLocks();
  scrollLock.enablePageScroll();
};

function setArticleBg() {
  let jobArticleBg = document.querySelector('.job-details-section .section-bg-layer');

  if (jobArticleBg) {
    let section = jobArticleBg.closest('section');
    let targetHeight = document.querySelector('.job-details-section__main-top').offsetHeight;
    console.log(parseFloat(window.getComputedStyle(section)['paddingTop']));
    jobArticleBg.style.height = targetHeight + parseFloat(window.getComputedStyle(section)['paddingTop']) + 'px';
  }
}

setArticleBg();
window.addEventListener('resize', () => {
  setArticleBg();
});
