const customAccordion = (elements, trigger, openOne = false) => {
    if (openOne) {
        elements[0].classList.add('is-active');

        elements.forEach(accordion => {
            const triggerBtn = accordion.querySelector(trigger);
           
            triggerBtn.addEventListener('click', () => {
                elements.forEach(accordion => {
                    accordion.classList.remove('is-active');
                })

                accordion.classList.add('is-active');
            })
        })
    } else {
        elements.forEach(accordion => {
            const triggerBtn = accordion.querySelector(trigger);
            triggerBtn.addEventListener('click', () => {
                accordion.classList.toggle('is-active');
            })
        })
    }
}

if (document.querySelector(".offers")) {
    const accordions = document.querySelectorAll('.js-accordion');
    customAccordion(accordions, '.js-accordion__head', true)
}