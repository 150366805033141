window.setSameHeight = function setSameHeight(elArr) {
  let minHeight = 0;
  let arr = typeof elArr === 'object' ? elArr : document.querySelectorAll(elArr);
  if (arr) {
    arr.forEach((el) => {
      el.style.minHeight = 0;
    });
    arr.forEach((el) => {
      if (minHeight < el.clientHeight) {
        minHeight = el.clientHeight;
      }
    });
    arr.forEach((el) => (el.style.minHeight = minHeight + 'px'));
  }
};

window.setBoxMbCounter = function setBoxMbCounter(scrollContainer, listenTagsName) {
  let tags = scrollContainer.querySelectorAll(listenTagsName);
  if (tags) {
    let mbCount = 0;
    let parentHeight = scrollContainer.clientHeight;
    let targetHeight = 0;

    tags.forEach((tag) => {
      let mb = parseFloat(window.getComputedStyle(tag)['marginBottom']);
      targetHeight += tag.offsetHeight + mb;
      if (parentHeight > targetHeight) {
        mbCount++;
      }
    });

    scrollContainer.style.setProperty('--mb-count', mbCount);
  }
};

window.popupClassicSimplebarInit = function popupClassicSimplebarInit() {
  if (document.querySelector('.popup [data-simplebar-popup]')) {
    const popupSimplebarArr = document.querySelectorAll('.popup [data-simplebar-popup]');
    popupSimplebarArr.forEach((el) => {
      const simplebar = new SimpleBar(el);
      setTimeout(function () {
        setBoxMbCounter(el, 'p');
      }, 100);
    });
  }
};

let articleOlList = $('.job-details-section.blog-details-section ol');
let articleUlList = $('.job-details-section.blog-details-section ul');
if(articleOlList.length){

  articleOlList.each((idx, ol)=>{
    let olH3 = $(ol).find('h3');
    let counter = 0
    if(olH3.length){
      olH3.closest('ol').addClass('js-counter-list')
      olH3.each((idx, h3)=>{
        if(idx < 9){
          counter = '0' + (idx + 1);
        }else{
          counter = idx;
        }
        $(h3).prepend(`<span class="js-counter">${counter}</span>`)
      })
    }
  })


}
if(articleUlList.length){

  articleUlList.each((idx, ul)=>{
    let ulH3 = $(ul).find('h3');
    if(ulH3.length){
      ulH3.closest('ul').addClass('js-ul-list')
    }
  })


}